import type { ClassNameProps } from '@/types/common.types';

const AmediatekaSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      width="32"
      height="41"
      viewBox="0 0 32 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 0H32V31.4C32 34.7603 32 36.4405 31.346 37.7239C30.7708 38.8529 29.8529 39.7708 28.7239 40.346C27.4405 41 25.7603 41 22.4 41H9.6C6.23969 41 4.55953 41 3.27606 40.346C2.14708 39.7708 1.2292 38.8529 0.653961 37.7239C0 36.4405 0 34.7603 0 31.4V0Z"
        fill="#121212"
      />
      <path
        d="M10.7933 30.9496H7L8.8604 25.479L9.00537 24.9748C9.04564 24.9328 9.06336 24.8134 8.81208 24.6723C8.56081 24.5311 8.70738 24.3782 8.81208 24.3193C9.19866 24.1345 10.054 23.7193 10.3826 23.5378C10.7111 23.3563 10.6161 23.1261 10.5275 23.0336C10.4872 22.9832 10.3536 22.837 10.1409 22.6555C9.92832 22.4739 9.9557 22.2437 9.99597 22.1513L14.0792 10H17.8966L21.1101 19.5546C21.3356 20.2101 21.8156 21.6118 21.9315 21.9748C22.0475 22.3378 22.5275 22.7143 22.753 22.8571C22.8094 22.8824 23.014 22.9731 23.3812 23.1345C23.7485 23.2958 23.6792 23.4538 23.5987 23.5126C23.2201 23.7395 22.4486 24.1933 22.3906 24.1933C22.3181 24.1933 21.9557 24.5462 22.4389 24.6975C22.8255 24.8185 22.9866 25.0504 23.0188 25.1513L25 31H21.1826L19.4575 25.9076H13.6685C13.7007 25.7815 13.7941 25.4739 13.9101 25.2521C14.0454 25.0504 13.9987 24.8992 13.9584 24.8487C13.9262 24.8235 13.8231 24.7479 13.6685 24.6471C13.5138 24.5462 13.604 24.437 13.6685 24.395C14.1678 24.1345 15.2196 23.5782 15.4322 23.437C15.6448 23.2958 15.5691 23.1092 15.5047 23.0336L14.949 22.5042V22.1765H18.2107L15.9879 15.6723C15.3517 17.5378 14.0405 21.3798 13.8859 21.8235C13.7313 22.2672 13.7893 22.5126 13.8376 22.5798C13.902 22.6471 14.0792 22.8218 14.2725 22.9832C14.4658 23.1445 14.3691 23.3529 14.2966 23.437L12.7262 24.2437C12.5329 24.3361 12.2285 24.5462 12.557 24.6471C12.8856 24.7479 12.8228 25.0924 12.7503 25.2521L10.7933 30.9496Z"
        fill="white"
        stroke="black"
        strokeWidth="0.0505007"
      />
    </svg>
  );
};

export default AmediatekaSVG;
