import { type ElementType } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Badge/Badge.module.scss';

// =================================================================

interface BadgeProps extends ClassNameProps, ChildrenProps {
  as?: ElementType;
  position: 'left' | 'right' | 'top' | 'bottom' | 'default';
  variant: 'free' | 'new' | 'quality' | 'quality-tv';
}

// =================================================================

export const Badge = (props: BadgeProps) => {
  const { as: Element = 'div', children, position = 'default', variant, className } = props;

  return (
    <Element className={clsx(styles.badgeWrapper, className)}>
      <span className={clsx(styles.badge, styles[variant], styles[position])}>{children}</span>
    </Element>
  );
};
