import { memo, useEffect, type MouseEventHandler } from 'react';

import clsx from 'clsx';

import FavoriteSVG from '@/icons/FavoriteSVG';
import FilledHeartSVG from '@/icons/FilledHeartSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { ResponseFavoriteHook } from '@/hooks/useFavorite';

import styles from '@/components/MovieCard/MovieFavoriteToggle.module.scss';
import { Spinner } from '@/components/Spinner';

// =================================================================

interface MovieFavoriteToggleProps
  extends Pick<
    ResponseFavoriteHook,
    'debouncedFetchFavoriteStatus' | 'debouncedSetLoading' | 'status' | 'isFavorite' | 'toggle'
  > {
  iconClassName?: string;
  onToggled?: () => void;
  isHovered: boolean;
  isInitiallyFavorite: boolean;
  className?: string;
}

// =================================================================

export const MovieFavoriteToggle = memo((props: MovieFavoriteToggleProps) => {
  const {
    className,
    iconClassName,

    isFavorite,
    onToggled,
    isHovered,
    debouncedFetchFavoriteStatus,
    debouncedSetLoading,
    status,
    toggle,
    isInitiallyFavorite,
  } = props;

  const { userAccessToken } = useVerification();
  const isAuthenticated = Boolean(userAccessToken);

  const handleToggleFavorite: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    toggle({ onToggled });
  };

  const isFavoriteButtonVisible = status === 'toggling' || status === 'ready';

  useEffect(() => {
    if (isAuthenticated && !isInitiallyFavorite && isHovered && !isFavoriteButtonVisible) {
      debouncedSetLoading();
      debouncedFetchFavoriteStatus();
    }
    return () => {
      debouncedSetLoading.cancel();
      debouncedFetchFavoriteStatus.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, isFavoriteButtonVisible, isAuthenticated, isInitiallyFavorite]);

  return (
    <button
      title={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
      onClick={handleToggleFavorite}
      disabled={status === 'toggling'}
      className={clsx(
        {
          [styles.favoriteButton]: true,
          [styles.active]: isFavorite,
        },
        className,
      )}
    >
      {isFavoriteButtonVisible && !isFavorite && (
        <FavoriteSVG className={iconClassName} width={25} height={25} />
      )}
      {isFavoriteButtonVisible && isFavorite && (
        <FilledHeartSVG className={iconClassName} width={25} height={25} />
      )}

      {!isFavoriteButtonVisible && (
        <Spinner className={styles.spinner} height={'20px'} width={'20px'} />
      )}
    </button>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  MovieFavoriteToggle.displayName = 'MovieFavoriteToggle';
}
