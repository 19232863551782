import type { SVGProps } from '@/types/common.types';

const WinkSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="24"
      viewBox="0 0 60 24"
      fill="none"
      {...props}
    >
      <rect opacity="0.7" width="60" height="24" rx="4" fill="black" />
      <g clipPath="url(#clip0_2_110)">
        <path
          d="M43.4014 14.8856L40.0974 11.604L43.177 8.49745C43.2501 8.42372 43.1981 8.29791 43.0941 8.29791H39.828L37.0469 11.1886V8.29791H34.0671V15.0857H37.0469V12.1307L39.9449 15.0857H43.3184C43.4236 15.0857 43.4756 14.9587 43.4014 14.8856Z"
          fill="white"
        />
        <path
          d="M29.7675 8.29791V11.7913L26.4955 8.29791H23.6618V15.0857H26.4955V11.5923L29.7675 15.0857H32.607V8.29791H29.7675Z"
          fill="white"
        />
        <path
          d="M15.5989 8.29792L14.22 12.4349L12.7711 8.29792H9.616L8.16701 12.4349L6.78814 8.29792H4.14724C4.06719 8.29792 4.01052 8.37691 4.03681 8.45298L6.30319 15.0857H9.76791L11.1643 11.1067L12.5607 15.0857H16.0254L18.2924 8.4524C18.3181 8.37633 18.262 8.29733 18.182 8.29733H15.5989V8.29792Z"
          fill="white"
        />
        <path d="M22.2012 8.29791H19.2214V15.0857H22.2012V8.29791Z" fill="white" />
        <path
          d="M49.0986 5.11001L46.1708 8.0428C46.0405 8.17329 46.0405 8.38394 46.1708 8.51443L49.3346 11.683L46.1556 14.8692C46.0259 14.9997 46.0259 15.2103 46.1556 15.3408L49.084 18.2736C49.2143 18.4041 49.4252 18.4041 49.5549 18.2736L55.8989 11.92C56.0292 11.7895 56.0292 11.5783 55.8989 11.4484L49.5695 5.11001C49.4398 4.97952 49.2289 4.97952 49.0986 5.11001Z"
          fill="url(#paint0_linear_2_110)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_110"
          x1="42.8544"
          y1="14.094"
          x2="58.9081"
          y2="8.15349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9532" />
          <stop offset="0.1669" stopColor="#FF8A1C" />
          <stop offset="0.5077" stopColor="#FF5B21" />
          <stop offset="0.7932" stopColor="#FF5A24" />
        </linearGradient>
        <clipPath id="clip0_2_110">
          <rect width="52" height="13.4" fill="white" transform="translate(4 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WinkSVG;
