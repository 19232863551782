import type { SVGProps } from '@/types/common.types';

const MegogoSVG = (props: SVGProps) => {
  return (
    <svg
      width="32"
      height="41"
      viewBox="0 0 32 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0H32V31.4C32 34.7603 32 36.4405 31.346 37.7239C30.7708 38.8529 29.8529 39.7708 28.7239 40.346C27.4405 41 25.7603 41 22.4 41H9.6C6.23969 41 4.55953 41 3.27606 40.346C2.14708 39.7708 1.2292 38.8529 0.653961 37.7239C0 36.4405 0 34.7603 0 31.4V0Z"
        fill="#121212"
      />
      <path d="M7.68889 15.8734L5 14.7118V26.5721L7.68889 24.3712V15.8734Z" fill="#22C3B0" />
      <path d="M8.17778 24.738L5.55 27H26.5111L23.9444 24.738H8.17778Z" fill="#22C3B0" />
      <path d="M24.3111 24.3712L27 26.5721V14.7118L24.3111 15.868V24.3712Z" fill="#22C3B0" />
      <path
        d="M23.7 24.1266H8.3V16.1179L15.9389 19.4192H16.0611L23.7 16.1179V24.1266Z"
        fill="#22C3B0"
      />
      <path d="M24.3111 15.1397V13L26.6944 14.131L24.3111 15.1397Z" fill="#22C3B0" />
      <path d="M23.7 15.5066V13L16.3056 16.0568V18.5939L23.7 15.5066Z" fill="#22C3B0" />
      <path d="M15.6944 18.5939V16.0568L8.3 13V15.5066L15.6944 18.5939Z" fill="#22C3B0" />
      <path d="M7.68889 15.1703V13L5.30556 14.1616L7.68889 15.1703Z" fill="#22C3B0" />
    </svg>
  );
};

export default MegogoSVG;
