import { MouseEventHandler } from 'react';

import { useCardRemove } from './useCardRemove';
import { useTranslations } from 'next-intl';

import TrashbinSVG from '@/icons/TrashbinSVG';

import { IconButtonProps } from '@/components/Buttons';
import { FilledIconButton } from '@/components/Buttons/FilledIconButton';

// =================================================================

export interface MovieRemoveButtonProps
  extends Pick<IconButtonProps, 'className' | 'iconHeight' | 'iconWidth'> {
  movieId: number;
  moduleId: number;
  onRemoved?: () => void;
}

// =================================================================

export const MovieRemoveButton = (props: MovieRemoveButtonProps) => {
  const { className, movieId, moduleId, onRemoved, ...rest } = props;

  const t = useTranslations('common');

  const { isRemoving, remove } = useCardRemove({
    deepRemove: true,
  });
  const handleRemove: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    remove({ moduleId, movieId, onRemoved });
  };

  return (
    <FilledIconButton
      as="button"
      icon={TrashbinSVG}
      iconWidth={25}
      iconHeight={25}
      title="Удалить из истории"
      onClick={handleRemove}
      disabled={isRemoving}
      {...rest}
    />
  );
};
