import { useEffect, useCallback } from 'react';
import { useSetState } from 'react-use';

import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';

import { MovieApi } from '@/api/domains/movie.api';
import { UserApi } from '@/api/domains/user-api';

// =================================================================

enum StatusEnum {
  READY = 'ready',
  LOADING = 'loading',
  TOGGLING = 'toggling',
  IDLE = '',
}

// =================================================================

type UserFavoriteConfig = {
  moduleId: number;
  movieId: number;
  isFavorite?: boolean;
};

type StateType = {
  isFavorite: boolean;
  status: StatusEnum;
};

type ToggleParams = {
  onToggled?: () => void;
};

export type ResponseFavoriteHook = {
  status: StatusEnum;
  isFavorite: boolean;
  toggle: (params?: ToggleParams) => void;
  fetchFavoriteStatus: () => void;
  debouncedFetchFavoriteStatus: DebouncedFunc<() => void>; // Update type to DebouncedFunc
  debouncedSetLoading: DebouncedFunc<() => void>; // Update type to DebouncedFunc
};
// =================================================================

export const useFavorite = (config: UserFavoriteConfig): ResponseFavoriteHook => {
  const { moduleId, movieId, isFavorite: isInitiallyFavorite = false } = config;

  const [state, setState] = useSetState<StateType>({
    isFavorite: isInitiallyFavorite,
    status: isInitiallyFavorite ? StatusEnum.READY : StatusEnum.IDLE,
  });

  // ----------------------------------------------------------------
  const toggle = async (params: ToggleParams = {}) => {
    const { onToggled } = params;
    try {
      setState({ status: StatusEnum.TOGGLING });

      const {
        data: { status: responseStatus },
      } = await MovieApi.toggleFavorite({ moduleId, movieId });

      setState({
        status: StatusEnum.READY,
        isFavorite: responseStatus === 'added' ? true : false,
      });

      if (typeof onToggled === 'function') {
        onToggled();
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  };

  // ----------------------------------------------------------------

  const fetchFavoriteStatus = useCallback(async () => {
    try {
      const {
        data: { is_favourite: isFileFavorite },
      } = await UserApi.checkIsFavorite({
        moduleId,
        movieId,
      });

      setState({ isFavorite: isFileFavorite, status: StatusEnum.READY });
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, movieId]);

  // ----------------------------------------------------------------

  const debouncedFetchFavoriteStatus = debounce(fetchFavoriteStatus, 500, {
    trailing: true,
  });

  const debouncedSetLoading = debounce(() => setState({ status: StatusEnum.LOADING }), 300);

  // ----------------------------------------------------------------

  useEffect(() => {
    if (isInitiallyFavorite) {
      setState({ isFavorite: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitiallyFavorite]);

  return {
    status: state.status,
    isFavorite: state.isFavorite,
    toggle,
    fetchFavoriteStatus,
    debouncedFetchFavoriteStatus,
    debouncedSetLoading,
  };
};
