import { useMemo } from 'react';

import { Marquee } from '../Marquee/Marquee';
import clsx from 'clsx';

import { ClassNameProps } from '@/types/common.types';

import type { Movie } from '@/api/types/movie.types';

import { commaSeparatedValues } from '@/helpers/transformations.helpers';

import { Card, CardBody, CardTitle, useCardHover } from '@/components/Card';
import styles from '@/components/MovieCard/MovieCard.module.scss';
import { MovieCardImageContainer } from '@/components/MovieCard/MovieCardImageContainer';

// =================================================================

export interface MovieCardProps extends ClassNameProps {
  movie: Movie;
  isFavorite?: boolean;
  isRemovable?: boolean;
  onToggled?: () => void;
  onRemoved?: () => void;
}

// =================================================================

export const MovieCard = (props: MovieCardProps) => {
  const { className, movie, ...rest } = props;

  const {
    module_id,
    id,
    title,
    genres,
    year,
    is_serial,
    poster,
    card_type,
    age_limit,
    is_free,
    is_new,
    is_download,
    countries,
    rating,
  } = movie;
  const genre = genres[0]?.title;
  const country = countries[0]?.title;

  const subtitle = useMemo(
    () => commaSeparatedValues([year, country, genre]),
    [genre, country, year],
  );

  const movieRating = useMemo(() => rating?.kp?.rating || rating?.imdb?.rating, [rating]);

  const { ref, isHovered } = useCardHover<HTMLDivElement>();

  return (
    <Card ref={ref} className={clsx(styles.movieCard, className)}>
      <MovieCardImageContainer
        isSerial={is_serial}
        title={title}
        poster={poster}
        moduleId={module_id}
        movieId={id}
        cardType={card_type}
        ageLimit={age_limit}
        isNew={is_new}
        isFree={is_free}
        isDownload={is_download}
        isHovered={isHovered}
        subtitle={subtitle}
        rating={movieRating}
        {...rest}
      />
      <CardBody className={styles.movieCardBody}>
        <CardTitle as="link" href={`/watch/${module_id}/${id}`} lines={1}>
          <Marquee isEnabled={isHovered}>{title}</Marquee>
        </CardTitle>
      </CardBody>
    </Card>
  );
};
