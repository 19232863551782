import { useEffect, useRef, useState } from 'react';
import { useLatest } from 'react-use';

// ----------------------------------------------------------------------

export type MarqueeConfig = {
  isEnabled?: boolean;
  gap?: number;
  // px/ms
  speed?: number;
  delay?: number;
};

// ----------------------------------------------------------------------

const isContentOverflowing = <
  ContainerElement extends HTMLElement,
  WrapperElement extends HTMLElement,
>(
  containerElement: ContainerElement,
  wrapperElement: WrapperElement,
) => wrapperElement.clientWidth > containerElement.clientWidth;

// ----------------------------------------------------------------------

export const useMarquee = (props: MarqueeConfig) => {
  const { isEnabled = false, gap = 1 } = props;

  const [canScroll, setCanScroll] = useState(false);
  const shouldRun = isEnabled && canScroll;

  const containerElementRef = useRef<HTMLDivElement>(null);
  const contentElementRef = useRef<HTMLDivElement>(null);
  const clonedContentElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const containerElement = containerElementRef.current;
    const wrapperElement = contentElementRef.current;

    if (!containerElement || !wrapperElement) {
      return;
    }

    if (isContentOverflowing(containerElement, wrapperElement)) {
      setCanScroll(true);
    }
  }, [isEnabled]);

  const latestMarqueeConfig = useLatest(props);
  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    const contentElement = contentElementRef.current;
    const clonedContentElement = clonedContentElementRef.current;

    if (!contentElement || !clonedContentElement) {
      return;
    }

    const { speed = 10, delay = 1000 } = latestMarqueeConfig.current;

    const animation = contentElement.animate(
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(${clonedContentElement.clientWidth * -1}px)` },
      ],
      {
        duration: clonedContentElement.clientWidth * speed,
        iterations: Infinity,
        easing: 'linear',
        delay,
      },
    );

    return () => animation?.cancel();
    /* eslint-disable-next-line */
  }, [shouldRun]);

  return {
    shouldRun,
    gap,
    containerElementRef,
    contentElementRef,
    clonedContentElementRef,
  };
};
