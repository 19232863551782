import { MouseEventHandler } from 'react';

import { useTranslations } from 'next-intl';

import DownloadSVG from '@/icons/DownloadSVG';

import { useMovieDownload } from '@/components/MovieCard/useMovieDownload';
import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { IconButtonProps } from '@/components/Buttons';
import { FilledIconButton } from '@/components/Buttons/FilledIconButton';

// =================================================================

export interface MovieDownloadButtonProps
  extends Pick<IconButtonProps, 'className' | 'iconHeight' | 'iconWidth'> {
  movieId: number;
  moduleId: number;
}

// =================================================================

export const MovieDownloadButton = (props: MovieDownloadButtonProps) => {
  const { className, movieId, moduleId, ...rest } = props;

  const t = useTranslations('common');

  const { userAccessToken } = useVerification();

  const { handleDownloadButtonClick } = useMovieDownload();

  const onDownloadButtonClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    handleDownloadButtonClick({ id: movieId, token: userAccessToken!, moduleId });
  };

  return (
    <FilledIconButton
      as="button"
      icon={DownloadSVG}
      onClick={onDownloadButtonClick}
      className={className}
      title={t('download')}
      iconWidth={25}
      iconHeight={25}
      {...rest}
    />
  );
};
