import type { SVGProps } from '@/types/common.types';

const StartTvSVG = (props: SVGProps) => {
  return (
    <svg
      width="60"
      height="24"
      viewBox="0 0 60 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.7" width="60" height="24" rx="4" fill="black" />
      <mask id="mask0_13_107" maskUnits="userSpaceOnUse" x="15" y="5" width="30" height="15">
        <path d="M15 5H45V20H15V5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_13_107)">
        <path
          d="M17.7403 5C15.8706 5 15 6.08375 15 7.77125V9.72875C15 11.48 15.3086 12.2262 16.5428 13.0175L17.4979 13.625C18.2693 14.1012 18.3684 14.4538 18.3684 15.4775V17.2025C18.3684 17.7088 18.1444 18.0162 17.7183 18.0162C17.2922 18.0162 17.0644 17.7275 17.0644 17.2062V14.495H15V17.225C15 18.9312 15.8522 20 17.6999 20C19.6063 20 20.4769 18.875 20.4769 17.2288V15.1025C20.4769 13.4788 20.0729 12.6425 18.8974 11.8775L17.9387 11.2475C17.2664 10.8087 17.1048 10.4975 17.1048 9.3725V7.79C17.1048 7.2875 17.3289 6.97625 17.7329 6.97625C18.1591 6.97625 18.4052 7.29125 18.4052 7.79V10.475H20.4732V7.775C20.4769 6.1025 19.6063 5 17.7403 5ZM21.1308 7.29125H22.5928V19.7938H24.7012V7.2875H26.1632V5.20625H21.1308V7.29125ZM39.9676 5.20625V7.29125H41.4295V19.7938H43.538V7.2875H45V5.20625H39.9676ZM27.4599 5.20625L26.2844 19.79H28.3709L28.6611 15.89H30.1928L30.4794 19.79H32.6136L31.4381 5.20625H27.4599ZM28.808 13.9138L29.3076 7.18625H29.5574L30.0533 13.9138H28.8117H28.808ZM39.1962 18.6875V14.8925C39.1962 13.685 38.748 13.0175 37.6717 12.7888V12.5375C38.7443 12.3688 39.2953 11.5587 39.2953 10.2462V7.77125C39.2953 6.16625 38.3623 5.20625 36.555 5.20625H33.6899V19.79H35.802V13.6888H37.0803V18.6875C37.0803 19.5163 37.4256 19.79 37.4256 19.79H39.5341C39.5414 19.79 39.1962 19.5238 39.1962 18.6875ZM37.1869 10.3513C37.1869 11.3975 36.9628 11.705 36.2685 11.705H35.8057V7.19H36.353C37.0032 7.19 37.1869 7.46 37.1869 8.54V10.3513Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default StartTvSVG;
