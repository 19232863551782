import { memo, ReactNode } from 'react';

import clsx from 'clsx';

import { MarqueeConfig, useMarquee } from '@/components/Marquee/useMarquee';

import { isWebAnimationsSupported } from '@/helpers/supports.helpers';

import styles from '@/components/Marquee/Marquee.module.scss';

// ----------------------------------------------------------------------

export interface MarqueeProps extends MarqueeConfig {
  className?: string;
  children?: ReactNode;
  gap?: number;
  speed?: number;
  delay?: number;
}

// ----------------------------------------------------------------------

const MarqueeWithAnimation = memo((props: MarqueeProps) => {
  const { isEnabled, className, children, gap = 10, speed = 20, delay = 0.5 } = props;

  const {
    shouldRun,
    gap: marqueeGap,
    containerElementRef,
    contentElementRef,
    clonedContentElementRef,
  } = useMarquee({
    isEnabled,
    gap,
    speed,
    delay,
  });

  return (
    <div ref={containerElementRef} className={clsx(styles.containerClassName, className)}>
      <div
        ref={contentElementRef}
        className={clsx(
          styles.contentClassName,
          isEnabled ? styles.contentEnabledClassName : styles.contentIdleClassName,
        )}
      >
        {children}
        {shouldRun && (
          <div
            ref={clonedContentElementRef}
            style={{ paddingLeft: `${marqueeGap}px` }}
            className={styles.clonedContentClassName}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
});

// ----------------------------------------------------------------------

const MarqueeWithoutAnimation = memo((props: MarqueeProps) => {
  const { children, className } = props;

  return (
    <div className={clsx(styles.containerClassName, className)}>
      <div className={clsx(styles.contentClassName, styles.contentIdleClassName)}>{children}</div>
    </div>
  );
});

// ----------------------------------------------------------------------

export const Marquee = isWebAnimationsSupported ? MarqueeWithAnimation : MarqueeWithoutAnimation;

// ----------------------------------------------------------------------

if (process.env.NODE_ENV !== 'production') {
  Marquee.displayName = 'Marquee';
}
