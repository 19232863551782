import { forwardRef } from 'react';

import clsx from 'clsx';

import { IconButton, IconButtonProps } from '@/components/Buttons';
import styles from '@/components/Buttons/FilledIconButton.module.scss';

// =================================================================

export const FilledIconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (props, ref) => {
    const { className, children, ...rest } = props;

    const classes = clsx(
      {
        [styles.filledIconButtonBase]: true,
      },
      className,
    );

    return (
      <IconButton className={classes} {...rest} ref={ref}>
        {children}
      </IconButton>
    );
  },
);

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  FilledIconButton.displayName = 'FilledIconButton';
}
