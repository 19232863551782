import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { MovieApi } from '@/api/domains/movie.api';

export const useMovieDownload = () => {
  const { checkAuthVerification } = useVerification();

  const handleDownloadButtonClick = checkAuthVerification<{
    id: number;
    token: string;
    moduleId: number;
  }>({
    onSuccess: async params => {
      const { id, token, moduleId } = params!;

      try {
        const { link: downloadLink } = await MovieApi.fetchDownloadLink({
          moduleId: moduleId.toString(),
          fileId: id.toString(),
          token,
        });

        window.open(downloadLink);
      } catch (error) {
        throw new Error(error.message);
      }
    },
  });

  return {
    handleDownloadButtonClick,
  };
};
