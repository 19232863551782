export enum MovieCardTypeEnum {
  CINERAMA = 'cinerama',
  DISNEY = 'disney',
  MORETV = 'moretv',
  TOPFILM = 'topfilm',
  START = 'start',
  AMEDIATIKA = 'amediateka',
  RIZANOVA = 'rizanova',
  MEGOGO = 'megogo',
  UZBEK = 'uzbek',
}
