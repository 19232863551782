import { useState } from 'react';

import { UserApi } from '@/api/domains/user-api';

// =================================================================

interface CardRemoveProps {
  deepRemove: boolean;
}

// =================================================================

export const useCardRemove = (props: CardRemoveProps) => {
  const { deepRemove } = props;
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const remove = async (params: { moduleId: number; movieId: number; onRemoved?: () => void }) => {
    const { moduleId, movieId, onRemoved } = params;

    try {
      setIsRemoving(true);
      if (deepRemove) {
        const { status } = await UserApi.removeDeepHistoryItem({ moduleId, movieId });
        if (status && typeof onRemoved === 'function') {
          onRemoved();
        }
      } else {
        const { status } = await UserApi.removeHistoryItem({ movieId });
        if (status && typeof onRemoved === 'function') {
          onRemoved();
        }
      }
      setIsRemoving(false);
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  };

  return { isRemoving, remove };
};
